/* Navbar.css */
.logo-container {
    display: flex;
    align-items: center;
}

.heartbeat {
    animation: heartbeat 1s infinite;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

/* Center the Nav.Link items */
.navbar-nav {
    width: 100%;
    justify-content: space-around;
}

/* Style the glowing button with bright shadow */
.btn-outline-light.glowing {
    box-shadow: 0 0 10px 5px #ffcc00;
    /* Bright shadow color */
    animation: glow 1.5s infinite;
}

@keyframes glow {
    0% {
        box-shadow: 0 0 10px 5px #ffcc00;
        /* Bright shadow color */
    }

    50% {
        box-shadow: 0 0 20px 10px #ffcc00;
        /* Increase the shadow size */
    }

    100% {
        box-shadow: 0 0 10px 5px #ffcc00;
        /* Back to the original shadow size */
    }
}

/* Add this to your CSS file or in a style tag in your component */
.navbar-nav-container {
    display: flex;
    justify-content: center;
}

.navbar-nav .nav-link {
    color: white !important;
    text-decoration: none !important;
    margin: 0 15px;
    cursor: pointer;
}

.navbar-nav .nav-link:hover {
    text-decoration: none;
    color: yellow;
    /* Change to your preferred hover color */
}


/* Glossy Button Styles */
.glossy-button {
    background-color: #4CAF50;
    /* Green background color */
    border: none;
    /* Remove border */
    color: white;
    /* White text color */
    padding: 12px 100px;
    /* Increased padding for larger size */
    text-align: center;
    /* Center text */
    text-decoration: none;
    /* Remove text underline */
    display: inline-block;
    /* Make it an inline block */
    font-size: 18px;
    /* Increased font size */
    margin: 10px 0;
    /* Adjusted margin */
    cursor: pointer;
    /* Cursor pointer */
    border-radius: 10px;
    /* Increased border radius */
    transition: background-color 0.3s, transform 0.3s;
    /* Added transition for background color and transform */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Add a subtle box shadow */
}

.glossy-button:hover {
    background-color: #45a049;
    /* Darker green on hover */
    transform: scale(1.05);
    /* Scale the button on hover for a subtle zoom effect */
}

.gradient-text {
    background: linear-gradient(to right, #fc6500, #dd884f);
    /* Adjust gradient colors as needed */
    -webkit-background-clip: text;
    color: transparent;
}



.title h2 {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 0;
    padding-bottom: 10px;
}

.underline {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    width: 250px;
    background: linear-gradient(to right, #0d055a, #ffcc00, #0d055a);
}

.title h2 {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 0;
    padding-bottom: 10px;
}



/* Emi calculater 0*/


.gradient-text {
    background: linear-gradient(to right, #ffd700, #666);
    -webkit-background-clip: text;
    color: transparent;
}

.underline {
    width: 60px;
    height: 4px;
    background-color: #ffd700;
    margin-top: 10px;
}

/* Custom styling for the footer */
#footer {
    background: linear-gradient(to right, #971b4f, #662b2b);
    /* Updated with gradient */
    color: #fff;
    padding: 20px 0;
    text-align: left;
}

.footer-column {
    margin-bottom: 30px;
    /* Add space between footer columns */
}

.footer-social img {
    width: 40px;
    /* Set the width of social icons */
    height: 40px;
    /* Set the height of social icons */
    margin-right: 10px;
    /* Add space between social icons */
}

.footer-social ul {
    list-style: none;
    /* Remove bullet points from the list */
    padding: 0;
    /* Remove padding from the list */
}

.footer-social li {
    display: inline-block;
    /* Display social icons in a line */
}

.footer-content {
    display: flex;
    /* Use flexbox for layout */
    justify-content: space-between;
    /* Space out the footer columns */
}

.logo-footer img {
    width: 150%;
    max-width: 200px;
    height: auto;
}

.footer-links,
.footer-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1em;
}

.footer-links a:hover {
    text-decoration: underline;
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/
.page-header {
    background-color: var(--thm-black);
    position: relative;
}

.page-header__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.page-header .container {
    position: relative;
    z-index: 10;
    padding-top: 90px;
    padding-bottom: 95px;
}

@media (max-width: 480px) {
    .page-header .container {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.page-header .container h2 {
    margin: 0;
    line-height: 1;
    color: #fff;
    font-size: 54px;
    font-weight: 700;
    margin-top: 5px;
    letter-spacing: .5px;
}

@media (max-width: 480px) {
    .page-header .container h2 {
        font-size: 42px;
    }
}

.thm-breadcrumb {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    display: inline-block;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, .09);
    padding: 11px 21px 11px 11px;
    border-radius: 4px;
    text-transform: capitalize;
}

.thm-breadcrumb li {
    line-height: 1;
    display: inline-block;
    align-items: center;
}

.thm-breadcrumb li:not(:last-of-type) {
    margin-right: 6px;
}

.thm-breadcrumb li:first-child {
    margin-left: 10px;
}

.thm-breadcrumb li a {

    padding-bottom: 3px;
    color: inherit;
}

.thm-breadcrumb li a:hover {

    color: #fff;
}

.thm-breadcrumb span a {
    margin: 0 10px;
}


/* Dashboard.css */
#sidebar {
    background-image: linear-gradient(to bottom, #971b4f, #971b4f);
    color: #fff;
    height: 100vh;
  }
  
  #sidebar .nav-card {
    margin-bottom: 20px;
    transition: transform 0.5s; /* Add transition for smooth hover effect */
  }
  
  #sidebar .nav-card:hover {
    transform: scale(1.05); /* Enlarge the card on hover */
  }
  
  #sidebar .admin-panel-card {
    height: 400px; /* Set the fixed height for the AdminPanel card */
    background: linear-gradient(to bottom, #ffd700, #666);
  }
  #sidebar .nav-link.active {

    color: #459900;
  }
  #sidebar .nav-link {
    color: #000607;
    margin-bottom: 10px;
    display: block;
    font-size: 20px;
  }